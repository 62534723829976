import HomePage from '../pages/home.f7';
import AboutPage from '../pages/about.f7';
import FormPage from '../pages/form.f7';

import ScreenReaderPolicyPage from '../pages/view-policy.f7';
import ScreenReaderScreenPage from '../pages/view-screen.f7';
import ScreenReaderQuizPage from '../pages/view-quiz.f7';
import ScreenReaderCongratulationsPage from '../pages/view-congratulations.f7';
import ScreenReaderResultsPage from '../pages/view-results.f7';
import ComplianceCalendarPage from '../pages/compliance-calendar.f7';
import SettingsPage from '../pages/settings.f7';
import PoliciesPage from '../pages/policies.f7';
import PrivacyPolicyPage from '../pages/privacy-policy.f7';
import PrivacyPolicyEditorPage from '../pages/pp-editor.f7';
import SupplierRegisterPage from '../pages/supplier-register.f7';
import DataRegisterPage from '../pages/data-register.f7';
import DataPrivacyRequests from '../pages/data-privacy-requests.f7';
import TenancySelectPage from '../pages/tenancy-list.f7';

import DynamicRoutePage from '../pages/dynamic-route.f7';
import RequestAndLoad from '../pages/request-and-load.f7';
import NotFoundPage from '../pages/404.f7';
import DeiterateCommon from './DeiterateCommon';

var routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/home/',
    name: 'home',
    component: HomePage,
    options: {
      animate: false,
      transition: 'none',
    },
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/privacy-policy/',
    name: 'privacy-policy',
    component: PrivacyPolicyPage,
    routes: [
      {
        path: 'editor/',
        component: PrivacyPolicyEditorPage,
      },
    ],
  },
  {
    path: '/tenancy-select/',
    name: 'tenancy-select',
    component: TenancySelectPage,
  },
  {
    path: '/supplier-register/',
    name: 'supplier-register',
    component: SupplierRegisterPage,
    options: {
      animate: false,
      transition: 'none',
    },
  },
  {
    path: '/data-register/',
    name: 'data-register',
    component: DataRegisterPage,
    options: {
      animate: true,
    },
  },
  {
    path: '/data-privacy-requests/:status',
    name: 'data-privacy-requests',
    component: DataPrivacyRequests,
    options: {
      animate: true,
    },
  },
  {
    path: '/policies/',
    name: 'policies',
    component: PoliciesPage,
    options: {
      animate: false,
      transition: 'none',
    },
  },
  {
    path: '/compliance-calendar/',
    component: ComplianceCalendarPage,
    options: {
      animate: false,
      transition: 'none',
    },
  },
  {
    path: '/settings/',
    component: SettingsPage,
    // options: {
    //   animate: false,
    //   transition: 'none',
    // },
  },

  // Screen Reader
  {
    path: '/screen-reader/policy/:policyId', // WIP
    // openIn: 'popup',
    name: 'view-policy',
    options: {
      transition: 'f7-cover-v',
    },
    routes: [
      {
        name: 'view-screen',
        path: 'screen/:screenId', // WIP
        // animate: false,
        // options: {
        //   transition: 'f7-cover-v',
        // },
        routes: [
          {
            name: 'view-congratulations',
            path: 'congratulations', // WIP
            async: function async({ router, to, resolve }) {
              // App instance
              var app = router.app;

              // Show Preloader
              app.preloader.show();

              // User ID from request
              var userId = to.params.userId;

              window.deit.getPolicyData(to.params.policyId, '').then((policyData) => {
                console.log(policyData);
                policyData.policy_history.created_date = window.deit.convertTimestamp(
                  policyData.policy_history.created_date
                );

                // Simulate Ajax Request
                window.deit.getUserData().then((userData) => {
                  console.log(userData);
                  window.deit.getCompanyName(false, true).then((data) => {
                    console.log(data);
                    userData.companyName = data['companyName'];
                    userData.customerName = data['customerName'];
                  });

                  new DeiterateCommon()
                    .getScreenData(to.params.policyId, to.params.screenId)
                    .then((screenData) => {
                      if (screenData == undefined || screenData == null) {
                        screenData = {};
                      }
                      screenData.control_objective = screenData.control_objective || '';

                      new DeiterateCommon()
                        .getCongratulations(to.params.policyId, to.params.screenId)
                        .then((congratulationsData) => {
                          console.log(congratulationsData);
                          // Hide Preloader
                          app.preloader.hide();

                          // Resolve route to load page
                          resolve(
                            {
                              component: ScreenReaderCongratulationsPage,
                            },
                            {
                              props: {
                                user: userData,
                                policy: { ref: to.params.policyId, data: policyData },
                                screen: { ref: to.params.screenId, data: screenData },
                                congratulations: {
                                  ref: to.params.screenId,
                                  data: congratulationsData,
                                },
                              },
                            }
                          );
                        });
                    });
                });
              });
            },
          },
          {
            name: 'view-quiz',
            path: 'quiz/:quizId',
            routes: [
              {
                name: 'view-results',
                path: 'results', // WIP
                async: function async({ router, to, resolve }) {
                  // App instance
                  var app = router.app;

                  // Show Preloader
                  app.preloader.show();

                  // User ID from request
                  var userId = to.params.userId;

                  window.deit.getPolicyData(to.params.policyId, '').then((policyData) => {
                    console.log(policyData);
                    policyData.policy_history.created_date = window.deit.convertTimestamp(
                      policyData.policy_history.created_date
                    );

                    // Simulate Ajax Request
                    window.deit.getUserData().then((userData) => {
                      console.log(userData);
                      window.deit.getCompanyName(false, true).then((data) => {
                        console.log(data);
                        userData.companyName = data['companyName'];
                        userData.customerName = data['customerName'];
                      });

                      new DeiterateCommon()
                        .getScreenData(to.params.policyId, to.params.screenId)
                        .then((screenData) => {
                          if (screenData == undefined || screenData == null) {
                            screenData = {};
                          }
                          screenData.control_objective = screenData.control_objective || '';

                          new DeiterateCommon()
                            .getQuizQuestions(to.params.policyId)
                            .then((quizQuestionsData) => {
                              console.log(quizQuestionsData);
                              new DeiterateCommon()
                                .getQuizAnswers(to.params.policyId, to.params.quizId)
                                .then((quizAnswersData) => {
                                  quizAnswersData = quizAnswersData
                                    .map((value) => ({ value, sort: Math.random() }))
                                    .sort((a, b) => a.sort - b.sort)
                                    .map(({ value }) => value);
                                  console.log(quizAnswersData);
                                  new DeiterateCommon()
                                    .getQuizResults(to.params.policyId, to.params.quizId)
                                    .then((quizResultsData) => {
                                      // Hide Preloader
                                      app.preloader.hide();

                                      // Resolve route to load page
                                      resolve(
                                        {
                                          component: ScreenReaderResultsPage,
                                        },
                                        {
                                          props: {
                                            user: userData,
                                            policy: { ref: to.params.policyId, data: policyData },
                                            screen: { ref: to.params.screenId, data: screenData },
                                            quiz: {
                                              ref: to.params.quizId,
                                              data: {
                                                questions: quizQuestionsData,
                                                answers: quizAnswersData,
                                                results: quizResultsData,
                                              },
                                              answers: [1],
                                            },
                                          },
                                        }
                                      );
                                    });
                                });
                            });
                        });
                    });
                  });
                },
              },
            ],
            async: function async({ router, to, resolve }) {
              // App instance
              var app = router.app;

              // Show Preloader
              app.preloader.show();

              // User ID from request
              var userId = to.params.userId;

              window.deit.getPolicyData(to.params.policyId, '').then((policyData) => {
                console.log(policyData);
                policyData.policy_history.created_date = window.deit.convertTimestamp(
                  policyData.policy_history.created_date
                );

                // Simulate Ajax Request
                window.deit.getUserData().then((userData) => {
                  console.log(userData);
                  window.deit.getCompanyName(false, true).then((data) => {
                    console.log(data);
                    userData.companyName = data['companyName'];
                    userData.customerName = data['customerName'];
                  });

                  new DeiterateCommon()
                    .getScreenData(to.params.policyId, to.params.screenId)
                    .then((screenData) => {
                      if (screenData == undefined || screenData == null) {
                        screenData = {};
                      }
                      screenData.control_objective = screenData.control_objective || '';

                      new DeiterateCommon()
                        .getQuizQuestions(to.params.policyId)
                        .then((quizQuestionsData) => {
                          console.log(quizQuestionsData);
                          new DeiterateCommon()
                            .getQuizAnswers(to.params.policyId, to.params.quizId)
                            .then((quizAnswersData) => {
                              quizAnswersData = quizAnswersData
                                .map((value) => ({ value, sort: Math.random() }))
                                .sort((a, b) => a.sort - b.sort)
                                .map(({ value }) => value);
                              console.log(quizAnswersData);
                              new DeiterateCommon()
                                .getQuizResults(to.params.policyId, to.params.quizId)
                                .then((quizResultsData) => {
                                  // Hide Preloader
                                  app.preloader.hide();

                                  // Resolve route to load page
                                  resolve(
                                    {
                                      component: ScreenReaderQuizPage,
                                    },
                                    {
                                      props: {
                                        user: userData,
                                        policy: { ref: to.params.policyId, data: policyData },
                                        screen: { ref: to.params.screenId, data: screenData },
                                        quiz: {
                                          ref: to.params.quizId,
                                          data: {
                                            questions: quizQuestionsData,
                                            answers: quizAnswersData,
                                            results: quizResultsData,
                                          },
                                        },
                                      },
                                    }
                                  );
                                });
                            });
                        });
                    });
                });
              });
            },
          },
        ],
        async: function async({ router, to, resolve }) {
          // App instance
          var app = router.app;

          // Show Preloader
          app.preloader.show();

          // User ID from request
          var userId = to.params.userId;

          window.deit.getPolicyData(to.params.policyId, '').then((policyData) => {
            console.log(policyData);
            policyData.policy_history.created_date = window.deit.convertTimestamp(
              policyData.policy_history.created_date
            );

            // Simulate Ajax Request
            window.deit.getUserData().then((userData) => {
              console.log(userData);
              window.deit.getCompanyName(false, true).then((data) => {
                console.log(data);
                userData.companyName = data['companyName'];
                userData.customerName = data['customerName'];
              });

              new DeiterateCommon()
                .getScreenData(to.params.policyId, to.params.screenId)
                .then((screenData) => {
                  if (screenData == undefined || screenData == null) {
                    screenData = {};
                  }
                  screenData.control_objective = screenData.control_objective || '';
                  let procedureObjects = {};

                  Object.keys(screenData).forEach((key) => {
                    if (key.includes('procedure_')) {
                      const sortedSteps = Object.entries(screenData[key])
                        .filter(([stepKey, stepValue]) => stepKey.includes('proc_step_'))
                        .sort(
                          ([aKey, aValue], [bKey, bValue]) =>
                            aValue.proc_step_id == bValue.proc_step_id ||
                            aValue.proc_step_id.localeCompare(bValue.proc_step_id)
                        )
                        .reduce((obj, [stepKey, stepValue]) => {
                          obj[stepKey] = stepValue;
                          return obj;
                        }, {});

                      screenData[key].sorted = sortedSteps;
                      procedureObjects[key] = { ...screenData[key], ...sortedSteps };
                    }
                  });

                  screenData.procedureObjects = procedureObjects;
                  console.log('Route', screenData);
                  screenData['objectives'] = {};
                  if (to.params.policyId == 'policy_07' && to.params.screenId == 'screen4') {
                    window.deit.getISMSRoles().then((ismsRoles) => {
                      if (ismsRoles !== undefined) {
                        screenData['ismsRoles'] = ismsRoles;
                      }

                      // Hide Preloader
                      app.preloader.hide();

                      // Resolve route to load page
                      resolve(
                        {
                          component: ScreenReaderScreenPage,
                        },
                        {
                          props: {
                            user: userData,
                            policy: { ref: to.params.policyId, data: policyData },
                            screen: { ref: to.params.screenId, data: screenData },
                          },
                        }
                      );
                    })
                  } else if (to.params.policyId == 'policy_07' && to.params.screenId == 'screen3') {
                    window.deit.getISMSObjectives().then((ismsObjectives) => {
                      if (ismsObjectives !== undefined) {
                        screenData['objectives'] = ismsObjectives;
                      }

                      // Hide Preloader
                      app.preloader.hide();

                      // Resolve route to load page
                      resolve(
                        {
                          component: ScreenReaderScreenPage,
                        },
                        {
                          props: {
                            user: userData,
                            policy: { ref: to.params.policyId, data: policyData },
                            screen: { ref: to.params.screenId, data: screenData },
                          },
                        }
                      );
                    });
                  } else if (to.params.policyId == 'policy_07' && to.params.screenId == 'screen2') {
                    window.deit.getISMSScope().then((ismsScope) => {
                      if (ismsScope !== undefined) {
                        screenData['ismsScope'] = ismsScope;
                      }
                      // Hide Preloader
                      app.preloader.hide();
  
                      // Resolve route to load page
                      resolve(
                        {
                          component: ScreenReaderScreenPage,
                        },
                        {
                          props: {
                            user: userData,
                            policy: { ref: to.params.policyId, data: policyData },
                            screen: { ref: to.params.screenId, data: screenData },
                          },
                        }
                      );
                    });
                  } else {
                    // Hide Preloader
                    app.preloader.hide();

                    // Resolve route to load page
                    resolve(
                      {
                        component: ScreenReaderScreenPage,
                      },
                      {
                        props: {
                          user: userData,
                          policy: { ref: to.params.policyId, data: policyData },
                          screen: { ref: to.params.screenId, data: screenData },
                        },
                      }
                    );
                  }
                });
            });
          });
        },
      },
    ],
    async: function async({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      window.deit.getPolicyData(to.params.policyId, '').then((policyData) => {
        console.log(policyData);
        policyData.policy_history.created_date = window.deit.convertTimestamp(
          policyData.policy_history.created_date
        );

        // Simulate Ajax Request
        window.deit.getUserData().then((userData) => {
          console.log(userData);
          window.deit.getCompanyName(false, true).then((data) => {
            console.log(data);
            userData.companyName = data['companyName'];
            userData.customerName = data['customerName'];
          });

          window.deit.getScreenData(to.params.policyId, 'screen0').then((screenData) => {
            console.log(screenData);
            // Hide Preloader
            app.preloader.hide();

            // Resolve route to load page
            resolve(
              {
                component: ScreenReaderPolicyPage,
              },
              {
                props: {
                  user: userData,
                  policy: { ref: to.params.policyId, data: policyData },
                  screen: { ref: 'screen0', data: screenData },
                },
              }
            );
          });
        });
      });
    },
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ],
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            props: {
              user: user,
            },
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
